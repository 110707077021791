/*
 * fonts
 */
@font-face {
  font-family: "SKODA Next Light";
  src: url("assets/SKODANext-Light/SKODANext-Light.woff2") format("woff2"),
    url("assets/SKODANext-Light/SKODANext-Light.woff") format("woff"),
    url("assets/SKODANext-Light/SKODANext-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SKODA Next Regular";
  src: url("assets/SKODANext-Regular/SKODANext-Regular.otf") format("opentype");
}

/*
 *  kendo
 */

$primary: #0e3a2f;
$primary2: #78faae;

$secondary: #394748;
$secondary2: #6f797a;
$secondary3: #a0a7a8;
$secondary4: #cacecf;
$secondary5: #e3e5e6;
$secondary6: #f1f2f2; /* digital use only */

$tertiary: #f15252;
$tertiary2: #0961a1;
$tertiary3: #1ed4df;
$tertiary4: #faeb67;
$tertiary5: #f7b046;

$base-theme: Default;
$skin-name: demo_temp;
$swatch-name: Default;
$border-radius: 2px;
$info: #3e80ed;
$success: #5ec232;
$warning: #fdce3e;
$error: #d51923;
$text-color: #4a4a4a;
$bg-color: #ffffff;
$base-text: #4a4a4a;
$base-bg: #f6f6f6;
$hovered-text: #4a4a4a;
$hovered-bg: $secondary5;
$selected-text: #ffffff;
$selected-bg: $primary;

$accent: #4ba82e;

/*
 *  application
 */
$font_familiy: "SKODA Next Light", Arial, sans-serif;
$font_size: 14px;
$font_weight: normal;

$viewtitle_font_familiy: "SKODA Next Light", Arial, sans-serif;
$viewtitle_font_size: 20px;
$viewtitle_font_weight: bold;

$dialogtitle_font_familiy: "SKODA Next Light", Arial, sans-serif;
$dialogtitle_font_size: 16px;
$dialogtitle_font_weight: bold;

$editor_groupheader_font_size: 16px;
$editor_groupheader_font_weight: bold;
$editor_label_line_height: 24px;
$editor_not_valid_color: $error;

$main_color: $primary;

$header_color: $text-color;
$header_background_color: #fff;
$header_bottom_border: 1px solid #fff;

$content_color: $text-color;
$content_background_color: $secondary6;

$footer_color: #fff;
$footer_background_color: $primary;
$footer_top_border: 1px solid #fff;

$widgettitle_font_familiy: "SKODA Next Light", Arial, sans-serif;
$widgettitle_font_size: 20px;
$widgettitle_font_weight: bold;
$widgettitle_bottom_border: 1px solid $primary;

$drawer-selected-bg: $primary;
