@import "./variables.scss";

.k-filter-menu .k-actions {
  display: none;
}

.k-filter-menu-container {
  width: auto !important;
}

.k-drawer {
  border-color: $main_color !important;
  background-color: transparent;
}

.k-drawer-item.k-state-selected, .k-drawer-item.k-selected.k-state-selected {
  // color the .k-state-selected cases, where we have to extend the coloring beyond k-selected class (see case below
  // at .k-drawer.item.k-selected)
  color: $selected_text;
  background-color: $main_color;
}

.k-drawer-item.k-selected, .k-drawer-item.k-selected.k-hover {
  // when moving from one k-drawer-item to another by routing, the previous k-drawer-item might still be k-selected while
  // the actual state is at the new k-drawer-item, displayed by .k-state-selected (see actions -> shipping-schedule transition)
  color: $selected-text;
  background-color: $main_color;
}

.k-drawer-item:hover {
  color: $text-color;
  background-color: $primary2;
}

.k-drawer-item:focus {
  box-shadow: none;
}

.k-tabstrip-items-wrapper .k-item:focus {
  box-shadow: none;
}

.k-content.k-active:focus {
  outline: 0;
}

.k-grid {
  td {
    padding: 0 !important;
  }
}

table.k-grid-table .k-master-row.k-selected {
  background-color: $primary2;
}

table.k-grid-table .k-master-row.k-selected:hover {
  background-color: darken($primary2, 10%);
}

table.k-grid-table tr.k-selected > td{
  background-color: inherit;
}

.k-notification-group {
  z-index: 100000;
}

.k-tabstrip-items li:not([aria-selected = "true"]) .k-link {
  color: $main_color;
  div {
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      transition: .3s ease-in-out;
    }

    &::before {
      top: -3px;
      left: -9px;
      border-top: 1px solid darken($primary, 20%);
      border-left: 1px solid darken($primary, 20%);
    }

    &::after {
      right: -10px;
      bottom: -6px;
      border-bottom: 1px solid darken($primary, 20%);
      border-right: 1px solid darken($primary, 20%);
    }

    &:hover::before,
    &:hover::after {
      width: calc(100% + 18px);
      height: calc(100% + 9px);
    }
  }
}

.k-state-active {
  color: $accent;
}

.k-checkbox:checked {
  border-color: lighten($primary, 5%);
  background-color: lighten($primary, 5%);
}

.login-button > button {
  background-color: $primary2 !important;
  color: $text-color;
  border-radius: 22.5px;
  span {
    margin-left: 5px;
    margin-right: 5px;
  }
  transition: background-color .15s linear;
}

.login-button > button:hover {
  background-color: lighten($primary2, 10%) !important;
  transition: background-color .15s linear;
}

input.k-input[required], .k-input-solid {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $primary;
  border-radius: 0;
}

input.k-input[required]:hover, input.k-input[required]:focus {
  border: none;
  border-bottom: 1px solid $primary;
  border-radius: 0;
}

.k-button-flat:focus::after, .k-button-flat.k-focus::after {
  opacity: 0;
}

input.k-input[required]:not(input:-internal-autofill-selected, input:-internal-autofill-selected:hover, input:-internal-autofill-selected:focus, input:-internal-autofill-selected:active), .k-input-solid, .k-input-solid:hover, .k-input-solid:focus-within, .k-input-solid:focus, .k-input-solid.k-focus {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: $primary;
  background-color: transparent;
  box-shadow: none;
}

.k-datepicker.k-input {
  border-color: rgba(0, 0, 0, 0.16);
}

.not-valid .k-datepicker > .k-input-button {
  border-right: 1px solid $editor_not_valid_color;
  border-top: 1px solid $editor_not_valid_color;
  border-left: 1px solid $editor_not_valid_color;
}

a:not([href = '#']):not([disabled]):not(:empty)::before {
  content: '';
  background-color: $primary2;
  position: absolute;
  left: 3%;
  bottom: 1px;
  width: 95%;
  height: 6px;
  z-index: -1;
  transition: all .1s ease-in-out;
}


a:not([href = '#']):not([disabled]):not(:empty):hover::before {
  left: -1px;
  bottom: 0;
  height: 100%;
  width: calc(100% + 2px);
}

a:not([href = '#']):not([disabled]):not(:empty) {
  position: relative;
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: 700;
  color: $text-color;
  text-decoration: none;
}

.k-icon-button:not(.k-disabled) > span {
}

.k-grid tbody > tr:not(.k-detail-row):hover {
  background-color: rgba(0, 0, 0, 0.12);
}
