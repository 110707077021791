@import "./variables.scss";
@import "@progress/kendo-theme-default/dist/all.scss";
@import "./styles_kendo.scss";

::selection {
  color: $secondary;
  background-color: $primary2;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $font_familiy;
  font-size: $font_size;
  font-weight: $font_weight;
}

clevermailing-dialog-base {
  display: flex;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

input:-internal-autofill-selected, input:-internal-autofill-selected:hover, input:-internal-autofill-selected:focus, input:-internal-autofill-selected:active {
  box-shadow: inset 0 0 0 1px $secondary6, inset 0 0 0 100px $secondary6;
}

.login {
  opacity: 1;
  transition: opacity .5s ease-in-out;
}

.login.loading {
  opacity: 0;
}
